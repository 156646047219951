<template>
  <div class="notification-settings profile-settings-wrap-new">
    <div class="common-white-container settings-container">
      <div class="back-link">
        <router-link :to="{name: 'NotificationsList'}">
          <svg-icon icon="arrow-left" />
          Back to App list
        </router-link>
      </div>
      <div class="page-title">Report Adjusting</div>
      <div v-if="app.id !== undefined" class="mb-10">
        <app-title :title="app.title"
                   :logo="app.logo"
                   :store-link="app.store_link"
                   :store="app.store" />
      </div>
      <div class="mb-10">Set periodicity and required content of reports.</div>
      <progress-indicator v-if="app.id === undefined">
        Loading...
      </progress-indicator>
      <div class="settings-wrap" v-else>
        <div class="flex top-block">
          <div class="flex f-align-center">
            <div class="circle">
              1
            </div>
            <div>Periodicity:</div>
            <div class="mode-switcher">
              <div :class="{active: notification.periodicity === 'monthly'}"
                   @click="changePeriodicity('monthly')">
                Monthly
              </div>
              <div :class="{active: notification.periodicity === 'weekly'}"
                   @click="changePeriodicity('weekly')">
                Weekly
              </div>
            </div>
          </div>
          <div class="flex f-align-center second-step">
            <div class="circle">
              2
            </div>
            <div>
              Countries:
            </div>
            <country-selector ref="countrySelector"
                              v-if="countries.length > 0"
                              :all-countries="countries"
                              :selected-outer="notification.selectedCountries"
                              @changed="changeCountries"/>
          </div>
        </div>
        <div class="flex f-column">
          <div class="flex f-j-between f-align-center full-width" style="flex-shrink: 0;">
            <div class="flex f-align-center">
              <div class="circle">
                3
              </div>
              <div>
                Select the content of the reports:
              </div>
            </div>
            <div class="deselect-button">
              <div v-if="isAllSelected" @click="changeAll(false)">
                Deselect All Content
              </div>
              <div v-else @click="changeAll(true)">
                Select All Content
              </div>
            </div>
          </div>
          <div class="settings">
            <div class="settings-block">
              <div class="visibility-wrap">
                <div class="checkboxes-block type-2">
                  <input type="checkbox"
                         id="visibility-checkbox"
                         v-model="notification.types.visibility.active">
                  <label for="visibility-checkbox">Visibility Changes</label>
                </div>
                <div class="visibility-block">
                  <div class="description">
                    Monitor how your app's search visibility evolves across different countries.
                  </div>
                  <div class="checkboxes-block type-2">
                    <input type="checkbox"
                           id="visibility-checkbox-1"
                           v-model="notification.types.visibility.tops['1']">
                    <label for="visibility-checkbox-1">Top 1</label>
                  </div>
                  <div class="checkboxes-block type-2">
                    <input type="checkbox"
                           id="visibility-checkbox-5"
                           v-model="notification.types.visibility.tops['5']">
                    <label for="visibility-checkbox-5">Top 5</label>
                  </div>
                  <div class="checkboxes-block type-2">
                    <input type="checkbox"
                           id="visibility-checkbox-10"
                           v-model="notification.types.visibility.tops['10']">
                    <label for="visibility-checkbox-10">Top 10</label>
                  </div>
                </div>
              </div>
              <div>
                <div class="checkboxes-block type-2">
                  <input type="checkbox"
                         id="tracking-visibility"
                         v-model="notification.types['tracking-visibility'].active">
                  <label for="tracking-visibility">Tracking Keywords Changes</label>
                </div>
                <div class="description">
                  Gain insights into the overall dynamics of your tracked keywords.
                </div>
              </div>
              <div>
                <div class="checkboxes-block type-2">
                  <input type="checkbox"
                         id="popularity-checkbox"
                         v-model="notification.types.popularity.active">
                  <label for="popularity-checkbox">Keywords Popularity Changes</label>
                </div>
                <div class="description">
                  Discover which of your tracked keywords have surged in popularity the most.
                </div>
              </div>
            </div>
            <div class="settings-block">
              <div>
                <div class="checkboxes-block type-2">
                  <input type="checkbox"
                         id="updates"
                         v-model="notification.types['top-moving'].active">
                  <label for="updates">Top Moving Up, Down</label>
                </div>
                <div class="description">
                </div>
              </div>
              <div>
                <div class="checkboxes-block type-2">
                  <input type="checkbox"
                         id="inapp"
                         v-model="notification.types['competitors-category'].active">
                  <label for="inapp">Competitors’ Category Growth</label>
                </div>
                <div class="description">
                </div>
              </div>
              <div>
                <div class="checkboxes-block type-2">
                  <input type="checkbox"
                         id="category-ranking"
                         v-model="notification.types['category-ranking'].active">
                  <label for="category-ranking">Category Ranking Changes</label>
                </div>
                <div class="description">
                  Get an overview of your app’s movement within category rankings, including insights on competitors with significant growth.
                </div>
              </div>
              <div>
                <div class="checkboxes-block type-2">
                  <input type="checkbox"
                         id="installs"
                         v-model="notification.types['monthly-aso'].active">
                  <label for="installs">Competitors Installs & Revenue Changes</label>
                </div>
                <div class="description">
                  Monthly updates on which competitors have seen the most significant increases in installs and revenue.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fourth-step">
          <div class="flex f-align-center">
            <div class="circle">
              4
            </div>
            <div>
              Send the reports on this email:
            </div>
          </div>
          <div class="email-inputs">
            <div v-for="(email, index) in emails" class="email-input-wrap">
              <input type="checkbox"
                     v-if="!isOneEmail"
                     :id="'email-checkbox-' + email.email"
                     v-model="emails[index].active">
              <label :for="'email-checkbox-' + email.email"></label>

              <template v-if="email.editable">
                <input type="email"
                       class="email-input"
                       v-model="emails[index].email" />
              </template>
              <template v-else>
                <span class="pb-4">{{ email.email }}</span>
              </template>
            </div>
            <div @click="addEmail"
                 v-if="isOneEmail"
                 class="add-email-link">
              <span>
                  <svg-icon icon="plus" /> Add another email
              </span>
            </div>
            <div v-if="emailsError" class="red">
              {{ emailsError }}
            </div>
          </div>
        </div>
        <div class="buttons flex f-align-center">
          <base-button height="36px"
                       width="300px"
                       @click="submit"
                       border-radius="4px">
            <span>Apply</span>
          </base-button>

          <base-button height="36px"
                       width="100px"
                       border-radius="4px"
                       @click="cancel"
                       mode="outline">
            <span>Cancel</span>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheNewHeader from "@/components/TheNewHeader/index.vue";
import CountrySelector from "@/views/Reports/components/CountrySelector/index.vue";
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import AppTitle from "@/components/Billets/AppTitle/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";

export default {
  components: {
    SvgIcon,
    AppTitle,
    ProgressIndicator,
    CountrySelector,
    TheNewHeader
  },
  data() {
    return {
      app: {},
      notification: null,
      defaultNotification: {
        periodicity: 'monthly',
        selectedCountries: ['US'],
        types: {
          'visibility': {
            active: true,
            tops: {
              '1': true,
              '5': true,
              '10': true,
            },
          },
          'tracking-visibility': {
            active: true,
          },
          'popularity': {
            active: true,
          },
          'top-moving': {
            active: true,
          },
          'competitors-category': {
            active: true,
          },
          'category-ranking': {
            active: true,
          },
          'monthly-aso': {
            active: true,
          },
        },
      },
      countries: [],
      emails: [],
      emailsError: null,
    }
  },
  mounted() {
    this.fetchData();
    this.fetchCountries();
  },
  methods: {
    addEmail() {
      this.emails.push({
        email: '',
        active: true,
        editable: true,
      });
    },
    validateEmails() {
      return this.activeEmails.find((email) => !this.validateEmail(email)) === undefined;
    },
    validateEmail(email) {
      if (email.includes(' ')) {
        return false;
      } else {
        let parts = email.split('@');
        if (parts.length !== 2 || parts[1].length < 3 || parts[1].split('.').length < 2) {
          return false;
        }
      }

      return true;
    },
    async submit() {
      if (this.activeEmails.length === 0) {
        this.emailsError = 'Please, provide at least one email.';
        return;
      } else if (!this.validateEmails()) {
        this.emailsError = 'Incorrect email address.';
        return;
      } else {
        this.emailsError = null;
      }

      let types = {};
      Object.keys(this.notification.types).forEach(type => {
        if (this.notification.types[type].active) {
          types[type] = {
            'type': type,
          }
        }
      });
      if (this.notification.types.visibility.active) {
        let tops = [];
        Object.keys(this.notification.types.visibility.tops).forEach(top => {
          if (this.notification.types.visibility.tops[top]) {
            tops.push(top);
          }
        });
        types.visibility.configs = {
          'tops': tops
        };
      }
      await httpRequest(
        'POST',
        process.env.VUE_APP_API_URL + this.$ApiUrls.appNotifications.APP_NOTIFICATIONS_SET,
        {
          app_id: this.app.id,
          periodicity: this.notification.periodicity,
          countries: this.notification.selectedCountries,
          configs: Object.values(types),
          emails: this.activeEmails,
        }
      );

      this.$router.push('/notifications-list');
    },
    changeAll(value) {
      Object.keys(this.notification.types).forEach((index) => {
        this.notification.types[index].active = value;
      });
    },
    cancel() {
      this.$router.push('/notifications-list');
    },
    async fetchData() {
      let result = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.appNotifications.APP_NOTIFICATIONS_VIEW
          + '?app_id=' + this.$route.params.app_id
      );

      if (Object.keys(result.notification).length > 0) {
        let tempNotification = {
          periodicity: result.notification.periodicity,
          selectedCountries: result.notification.countries,
        };
        let tempTypes = {...this.defaultNotification.types};
        Object.keys(tempTypes).forEach((key) => {
          tempTypes[key]['active'] = false;
        });
        result.notification.types.forEach((type) => {
          let newType = {};
          if (Object.keys(type.configs).length > 0) {
            newType = type.configs;
          }
          newType.active = true;
          tempTypes[type.type] = {...newType};
        });
        tempNotification.types = tempTypes;

        if (tempNotification.types?.visibility?.tops !== undefined) {
          let tempTops = {...this.defaultNotification.types.visibility.tops};
          Object.keys(tempTops).forEach((key) => {
            tempTops[key] = false;
          });
          if (tempNotification.types.visibility.active) {
            tempNotification.types?.visibility?.tops?.forEach((top) => {
              tempTops[top] = true;
            });
          }
          tempNotification.types.visibility.tops = tempTops;
        }

        this.notification = {...tempNotification};
      } else {
        this.notification = {...this.defaultNotification};
      }
      this.setEmails(result.emails, result.user_email);

      this.app = result.app;
    },
    setEmails(emails, userEmail) {
      let newEmails = {};
      newEmails[userEmail] = {
        email: userEmail,
        active: emails.length === 0,
        editable: false
      }
      emails.forEach((email) => {
        newEmails[email] = {
          email: email,
          active: true,
          editable: email !== userEmail,
        }
      });
      this.emails = Object.values(newEmails);
    },
    changeCountries(codes) {
      this.notification.selectedCountries = codes;
    },
    changePeriodicity(periodicity) {
      this.notification.periodicity = periodicity;
    },
    async fetchCountries() {
      let url = process.env.VUE_APP_API_URL + this.$ApiUrls.reports.REPORT_COUNTRIES + '?app_id=' + this.currentApp.id
        + '&date_from=2024-01-12&date_to=2024-03-12&source=tracking' // todo remove
      ;
      let countries = await httpRequest('GET', url);
      if (this.countries.length === 0 && countries instanceof Array && countries.length > 0) {
        this.countries = countries;
      }
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
    ]),
    isAllSelected() {
      return !Object.values(this.notification.types).find((type) => type.active === false);
    },
    isOneEmail() {
      return Object.keys(this.emails).length < 2;
    },
    activeEmails() {
      return this.emails.filter((email) => email.active).map((email) => email.email);
    },
  },
  watch: {
    'notification.types.visibility.active'(newVal, oldVal) {
      if (oldVal !== null) {
        Object.keys(this.notification.types.visibility.tops).forEach((index) => {
          this.notification.types.visibility.tops[index] = newVal;
        });
      }
    },
  },
}
</script>

<style src="./../styles.scss" lang="scss"></style>
<style src="./styles.scss" lang="scss"></style>
